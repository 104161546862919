@import './assets/styles/reset';

body {
  margin: 0;
  padding: 0;
  font-family: 'CustomFont-Regular', sans-serif;
  font-size: 16px;
  color: #313131;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  font-weight: bold;
}

h4 {
  font-size: 18px;
}

h3 {
  font-size: 24px;
}

h2 {
  font-size: 24px;
}

.react-datepicker {
  &__day,
  &__month-text,
  &__quarter-text {
    &--selected,
    &--in-selecting-range,
    &--in-range,
    &--keyboard-selected {
      &,
      &:hover {
        background-color: #5c18b1 !important;
      }
    }
  }

  &__close-icon {
    &:after {
      background-color: #5c18b1 !important;
    }
  }

  &-popper {
    z-index: 9999;
  }
}
